import { Lead } from './types'
import { LeadsRepository } from './LeadsRepository'
import firebase from 'firebase'

export class FirebaseLeadsRepository implements LeadsRepository {
  async getLead(leadId: string): Promise<Lead> {
    const lead = await firebase.functions().httpsCallable('handleGetLead')({
      id: leadId,
    })
    if (!lead) throw new Error('Lead not found')
    return lead.data
  }
  async postTeamData(formData: Lead): Promise<void> {
    await firebase.functions().httpsCallable('createATeamFormSubmit')(formData)
    return
  }
}
