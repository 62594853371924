import firebase from 'firebase/app'
import firebaseConfig from './config/firebase-config.json'
import config from './config/config.json'

export const initFirebase = () => {
  console.log('initting firebase')
  firebase.initializeApp(firebaseConfig)
  if (config.env === 'local') {
    firebase.functions().useEmulator('localhost', 5001)
  }
}
