import React, { useReducer, useState } from 'react'
import Button from '../common/Button'
import TeamDetails from './TeamDetails'
import TeamMembers from './TeamMembers/TeamMembers'
import { reducer } from '../../reducer'
import { initialTeamState } from '../../initialTeamState'
import SubHeaderText from '../common/SubHeaderText'
import i18n from '../../i18n/i18n'
import { FirebaseLeadsRepository } from '../../FirebaseLeadsRepository'
import { Member } from '../../types'
import TeamTelefoonSpinner from '../common/TeamTelefoonSpinner'
import { logError } from '../../logging/SentryLogger'
const { v4: uuidv4 } = require('uuid')

interface Props {
  id: string
  email: string
  name: string
  organization: string
  status: string
}

const MasterForm = ({ id, email, name, organization, status }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialTeamState)
  const [isSubmittingForm, setIsSubmittingForm] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const [hasFormBeenSuccesfullySubmitted, setHasFormBeenSuccesfullySubmitted] =
    useState(false)

  const preventEnterFromSubmitting = (e: React.KeyboardEvent) => {
    if (e.which === 13) e.preventDefault()
  }

  const handleTeamNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, value } = event.target
    dispatch({ type: 'setTeamName', payload: { [name]: value } })
  }

  const removeTeamMember = (memberId: Member['id']) =>
    dispatch({
      type: 'removeTeamMember',
      payload: { id: memberId },
    })

  const addTeamMember = (member: Member) =>
    dispatch({
      type: 'addTeamMember',
      payload: { ...member, id: uuidv4() },
    })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!state.name || state.members.length === 0) {
      return setResponseMessage(i18n.t('error.invalidFields'))
    }

    setIsSubmittingForm(true)
    const leadsRepository = new FirebaseLeadsRepository()

    try {
      await leadsRepository.postTeamData({
        id,
        email,
        name,
        organization,
        status,
        team: state,
      })

      setResponseMessage(i18n.t('lead.teamDataSuccesfullySubmitted'))
      setHasFormBeenSuccesfullySubmitted(true)
      dispatch({ type: 'resetTeamState' })
    } catch (error) {
      logError(error)
      setResponseMessage(i18n.t('error.teamDataUnsuccesfullySubmitted'))
    }

    setIsSubmittingForm(false)
  }

  return (
    <form onSubmit={handleSubmit} onKeyPress={preventEnterFromSubmitting}>
      <TeamDetails
        handleTeamNameChange={handleTeamNameChange}
        teamName={state.name}
      />
      <TeamMembers
        members={state.members}
        addTeamMember={addTeamMember}
        removeTeamMember={removeTeamMember}
      />
      <Button
        disabled={isSubmittingForm || hasFormBeenSuccesfullySubmitted}
        type="submit"
      >
        {isSubmittingForm ? (
          <TeamTelefoonSpinner height={'20px'} />
        ) : (
          i18n.t('team.createTeam')
        )}
      </Button>
      <SubHeaderText>{responseMessage}</SubHeaderText>
    </form>
  )
}

export default MasterForm
